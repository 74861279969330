import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertService } from '@broadstone/alert';
import { PopNotificationService } from '@broadstone/pop-notification-service';
import { EmployeesService } from 'src/services/public_api';
import * as moment from 'moment';
@Component({
	selector: 'app-worker-statuses',
	templateUrl: './worker-statuses.component.html',
	styleUrls: ['./worker-statuses.component.scss'],
})
export class WorkerStatusesComponent {
	modalContent: any; // @todo added for build to pass - update type and default value
	@Input() statusModalVisible: boolean = false;
	@Input() worker: any;
	@Output() statusModalVisibleChange: EventEmitter<any> = new EventEmitter();
	@Output() refreshWorkers: EventEmitter<any> = new EventEmitter();
	removedDropDownItem: string[] = ['None', 'Expired', 'Expired Compliant'];
	workStatusesData: any = [
		{
			title: 'RTW',
			subtitle: '',
			effectiveDate: '',
			valueName: 'checkRtwStatus',
			expiresDate: '',
			statusTypesSelectedIndex: 0,
			statusTypes: [
				{ title: 'None', value: 'none', selected: false },
				{ title: 'British/Irish Passport', value: 'British/Irish Passport', selected: false },
				{
					title: 'UK Birth Certificate',
					value: 'UK Birth Certificate',
					selected: false,
				},
				{
					title: 'Expired Passport',
					value: 'Expired Passport',
					selected: false,
				},
				{ title: 'Share Code: Tier 4', value: 'Share Code: Tier 4', selected: false },
				{ title: 'Share Code: Sponsorship', value: 'Share Code: Sponsorship', selected: false },
				{ title: 'ECS Check', value: 'ECS Check', selected: false },
			],
			statusSelectedIndex: 0,
			statuses: [
				{ title: 'None', value: 'none', selected: false },
				{ title: 'Approved', value: 'approved', selected: false },
				{ title: 'Pending', value: 'pending', selected: false },
				{ title: 'Rejected', value: 'rejected', selected: false },
				{ title: 'Expired', value: 'expired', selected: false },
			],
		},
		{
			title: 'ID Check',
			subtitle: '',
			effectiveDate: '',
			valueName: 'checkIdStatus',
			expiresDate: '',
			statusTypesSelectedIndex: 0,
			statusTypes: [
				{ title: 'None', value: 'none', selected: false },
				{
					title: 'British/Irish Passport',
					value: 'British/Irish Passport',
					selected: false,
				},
				{
					title: 'Other Passport',
					value: 'Other Passport',
					selected: false,
				},
				{
					title: 'Drivers Licence',
					value: 'Drivers Licence',
					selected: false,
				},
				{
					title: 'National Identity Card',
					value: 'National Identity Card',
					selected: false,
				},
				{ title: 'Other', value: 'Other' },
			],
			statusSelectedIndex: 0,
			statuses: [
				{ title: 'None', value: 'none', selected: false },
				{ title: 'Approved', value: 'approved', selected: false },
				{ title: 'Pending', value: 'pending', selected: false },
				{ title: 'Rejected', value: 'rejected', selected: false },
				{ title: 'Expired', value: 'expired', selected: false },
			],
		},
		{
			title: 'BS7858 Clearance',
			subtitle: '',
			effectiveDate: '',
			valueName: 'complianceStatus',
			expiresDate: 'not applicable',
			partiallyCompliantAt: '',
			statusSelectedIndex: 0,
			statuses: [
				{ title: 'None', value: 'none' },
				{
					title: 'Not compliant',
					value: 'not_compliant',
					selected: false,
				},
				{
					title: 'Limited Screening',
					value: 'partially_compliant',
					selected: false,
				},
				{
					title: 'Fully Compliant',
					value: 'fully_compliant',
					selected: false,
				},
				{
					title: 'Expired Compliant',
					value: 'expired_compliant',
					selected: false,
				},
			],
		},
		{
			title: 'Vetting Partners',
			subtitle: '',
			effectiveDate: '',
			valueName: 'vettingPartner',
			expiresDate: 'not applicable',
			statusTypesSelectedIndex: 0,
			statusTypes: [
				{ title: 'None', value: 'none' },
				{
					title: 'CV-Online',
					value: 'CV-Online',
					selected: false,
				},
				{
					title: 'Deploi',
					value: 'Deploi',
					selected: false,
				},
				{
					title: 'Internal',
					value: 'Internal',
					selected: false,
				},
				{
					title: 'NSA',
					value: 'NSA',
					selected: false,
				},
			],
		},
	];
	renderDone: boolean = false;
	viewAudit: boolean = false;
	workerAudit: string[] = [];
	gDriveUrl: string = '';
	viewLicences: boolean = false;

	modalSIAImageVisible: boolean = false;
	modalImage: string = '';
	siaLicenceColor: string = '';
	disabledDate = (current: Date): boolean => {
		const today = new Date();
		return current < today;
	};
	constructor(
		private employeesService: EmployeesService,
		private alert: AlertService,
		private popNotif: PopNotificationService,
	) {}

	ngOnChanges() {
		if (this.worker) {
			if (this.worker.profile?.sia_licences && typeof this.worker.profile.sia_licences === 'object') {
				this.worker.profile.sia_licences = Object.values(this.worker.profile.sia_licences);
			}
			this.markSiaColor();
			this.mapDate();
			this.removeClearedStatus();
			this.mapStatusesSelection();
			this.mapWorkerAudit();
			setTimeout(() => {
				this.renderDone = true;
			}, 5);
			this.gDriveUrl = this.worker.statuses.complianceDocumentsUrl;
		}
	}

	mapDate() {
		this.workStatusesData.map((element) => {
			if (element.valueName === 'checkRtwStatus') {
				element.effectiveDate = this.worker.statuses.rtwEffectiveDate;
				element.expiresDate = this.worker.statuses.rtwExpiresDate;
			}
			if (element.valueName === 'checkIdStatus') {
				element.effectiveDate = this.worker.statuses.idEffectiveDate;
				element.expiresDate = this.worker.statuses.idStatusExpiresDate;
			}
			if (element.valueName === 'complianceStatus') {
				element.effectiveDate = this.worker.statuses.complianceEffectiveFrom;
				element.partiallyCompliantAt = this.worker.statuses.madePartiallyCompliantAt;
				element.daysLeftLimitedScreening = this.worker.statuses.daysLeftLimitedScreening;
			}
		});
	}

	mapWorkerAudit() {
		const userNameRegex = /(User .*?) at/; // Regular expression to match everything up to but not including "at"
		this.workerAudit = this.worker.statuses.workerStatusAudit.map((item) => {
			return (
				`<br>` +
				item.replace(userNameRegex, (match, p1) => {
					return `<span class='b-font-demibold'>${p1}</span> at`;
				})
			);
		});
	}

	removeClearedStatus() {
		if (!this.worker.details.registeredSectors.includes('Security')) {
			this.workStatusesData.findIndex((element) => element.valueName === 'complianceStatus');
			this.workStatusesData.splice(2, 1);
		}
	}

	mapStatusesSelection() {
		this.workStatusesData.forEach((element: any) => {
			const { valueName } = element;
			const status = this.worker.statuses[valueName];
			if (!status) return;

			const statusSelectedIndex = element.statuses
				? element.statuses.findIndex((item: any) => item.value === status)
				: -1;
			if (statusSelectedIndex > -1) {
				element.statusSelectedIndex = statusSelectedIndex;
			}
			const { rtwStatusType, idStatusType, vettingPartner } = this.worker.statuses;

			if (valueName === 'checkRtwStatus') {
				const statusTypesSelectedIndexRTW = element.statusTypes.findIndex(
					(item: any) => item.value === rtwStatusType,
				);
				if (statusTypesSelectedIndexRTW > -1) {
					element.statusTypesSelectedIndex = statusTypesSelectedIndexRTW;
				}
			}
			if (valueName === 'checkIdStatus') {
				const statusTypesSelectedIndexID = element.statusTypes.findIndex(
					(item: any) => item.value === idStatusType,
				);
				if (statusTypesSelectedIndexID > -1) {
					element.statusTypesSelectedIndex = statusTypesSelectedIndexID;
				}
			}
			if (valueName === 'vettingPartner') {
				const statusTypesSelectedIndexPartner = element.statusTypes.findIndex(
					(item: any) => item.value === vettingPartner,
				);
				if (statusTypesSelectedIndexPartner > -1) {
					element.statusTypesSelectedIndex = statusTypesSelectedIndexPartner;
				}
			}
		});
	}

	ngOnInit(): void {}

	closeStatusModal() {
		this.statusModalVisible = false;
		this.statusModalVisibleChange.emit(this.statusModalVisible);
	}

	confirm() {
		this.alert.showConfirm('Are you sure?', 'Do you wish to proceed with these changes?', 'Yes').then(() => {
			this.submit();
		});
	}

	submit() {
		const updateObj = {
			worker_statuses: {},
		};

		// Check id status expires date
		const idStatusExpiresDate = this.getExpiresDate('checkIdStatus');
		if (this.worker.statuses.idStatusExpiresDate !== idStatusExpiresDate) {
			updateObj.worker_statuses['id_status_expires_date'] = idStatusExpiresDate
				? moment(idStatusExpiresDate).format('YYYY-MM-DD')
				: null;
		}

		// Check right to work expires date
		const rtwExpiresDate = this.getExpiresDate('checkRtwStatus');
		if (this.worker.statuses.rtwExpiresDate !== rtwExpiresDate) {
			updateObj.worker_statuses['right_to_work_expires_date'] = rtwExpiresDate
				? moment(rtwExpiresDate).format('YYYY-MM-DD')
				: null;
		}

		if (this.worker.details.registeredSectors.includes('Security')) {
			// Check and add 'complianceStatus' statuses
			const complianceStatusStatuses = this.getSelectedValue('complianceStatus', 'statuses');
			if (
				complianceStatusStatuses !== 'none' &&
				complianceStatusStatuses !== 'expired_compliant' &&
				this.worker.statuses.complianceStatus !== complianceStatusStatuses
			) {
				updateObj.worker_statuses['compliance_status'] = complianceStatusStatuses;
			}
		}

		// Check and add 'checkIdStatus' statuses
		const checkIdStatusStatuses = this.getSelectedValue('checkIdStatus', 'statuses');
		if (
			checkIdStatusStatuses !== 'none' &&
			checkIdStatusStatuses !== 'expired' &&
			this.worker.statuses.checkIdStatus !== checkIdStatusStatuses
		) {
			updateObj.worker_statuses['id_status'] = checkIdStatusStatuses;
		}

		// Check and add 'checkIdStatus' statusTypes
		const checkIdStatusStatusTypes = this.getSelectedValue('checkIdStatus', 'statusTypes');
		if (checkIdStatusStatusTypes !== 'none' && this.worker.statuses.idStatusType !== checkIdStatusStatusTypes) {
			updateObj.worker_statuses['id_status_type'] = checkIdStatusStatusTypes;
		}

		// Check and add 'checkRtwStatus' statuses
		const checkRtwStatusStatuses = this.getSelectedValue('checkRtwStatus', 'statuses');
		if (
			checkRtwStatusStatuses !== 'none' &&
			checkRtwStatusStatuses !== 'expired' &&
			this.worker.statuses.checkRtwStatus !== checkRtwStatusStatuses
		) {
			updateObj.worker_statuses['right_to_work'] = checkRtwStatusStatuses;
		}

		// Check and add 'checkRtwStatus' statusTypes
		const checkRtwStatusStatusTypes = this.getSelectedValue('checkRtwStatus', 'statusTypes');
		if (checkRtwStatusStatusTypes !== 'none' && this.worker.statuses.rtwStatusType !== checkRtwStatusStatusTypes) {
			updateObj.worker_statuses['right_to_work_type'] = checkRtwStatusStatusTypes;
		}

		// Check and add 'vettingPartner' statusTypes
		const vettingPartner = this.getSelectedValue('vettingPartner', 'statusTypes');
		if (vettingPartner !== 'none' && this.worker.statuses.vettingPartner !== vettingPartner) {
			updateObj.worker_statuses['vetting_partner'] = vettingPartner;
		}

		if (this.gDriveUrl !== this.worker.statuses.complianceDocumentsUrl) {
			updateObj.worker_statuses['compliance_documents_url'] = this.gDriveUrl;
		}

		// Check if there are any changes
		if (Object.keys(updateObj.worker_statuses).length === 0) {
			this.popNotif.createNotification(
				'warning',
				'No changes have been made.',
				'Nothing on this form has been changed.',
			);
			return;
		}

		this.employeesService
			.updateWorker(updateObj, this.worker.details.userUuid)
			.then((result) => {
				this.popNotif.createNotification('success', 'Success', 'Worker status updated.');
				this.refreshWorkers.emit();
				this.closeStatusModal();
			})
			.catch((err) => {
				if (typeof err === 'string') {
					this.popNotif.createNotification('error', 'There has been a problem.', err);
				} else {
					let errorMessages = '';
					for (const key in err) {
						if (err.hasOwnProperty(key)) {
							errorMessages += err[key].join(' <br>') + ' <br>';
						}
					}

					// Trim any extra whitespace
					errorMessages = errorMessages.trim();
					this.popNotif.createNotification('error', 'There has been a problem.', errorMessages);
				}
			});
	}

	getExpiresDate(item: 'checkRtwStatus' | 'checkIdStatus') {
		const found = this.workStatusesData.find((element) => element.valueName === item);
		if (!found) return null;
		return found.expiresDate ? found.expiresDate : null;
	}

	getSelectedValue(
		item: 'checkRtwStatus' | 'checkIdStatus' | 'complianceStatus' | 'vettingPartner',
		list: 'statusTypes' | 'statuses',
	) {
		const found = this.workStatusesData.find((element) => element.valueName === item);
		if (!found) return;
		const valueSelected = found[list].find((type) => type.selected);
		return valueSelected.value;
	}

	topUpColors(countDown) {
		if (countDown >= 31 && countDown < 61) {
			return 'warning';
		} else if (countDown >= 61) {
			return 'green';
		} else {
			return 'danger';
		}
	}

	resetSelections(list) {
		this.workStatusesData.forEach((item) => {
			if (item[list]) {
				item[list].forEach((type) => (type.selected = false));
			}
		});
	}
	selectedItem(list, item, selectedVal) {
		item[list].forEach((element) => {
			element.selected = false;
		});
		selectedVal.selected = true;
	}
	closeAuditDrawer() {
		this.viewAudit = false;
	}
	openAuditDrawer() {
		this.viewAudit = true;
	}

	openSiaDrawer() {
		this.viewLicences = true;
	}
	closeSiaDrawer() {
		this.viewLicences = false;
	}

	checkSiaExpired(expiryDate, about_exp = false) {
		if (expiryDate) {
			const currentDate = moment(moment());
			const futureMonth = moment(currentDate).add(3, 'M');
			const date = about_exp ? futureMonth : currentDate;
			return date.isSameOrAfter(moment(new Date(expiryDate)));
		}
	}

	openDriveLink() {
		window.open(this.gDriveUrl, '_blank');
	}
	openImageViewer(imgSide: 'front' | 'back', attachment) {
		if (!attachment?.front_img_url || !attachment?.back_img_url) return;

		this.modalSIAImageVisible = true;
		if (imgSide === 'front') {
			this.modalImage = attachment.front_img_url;
		}
		if (imgSide === 'back') {
			this.modalImage = attachment.back_img_url;
		}
	}
	closeModal() {
		this.modalSIAImageVisible = false;
		this.modalImage = '';
	}

	validateSia(sia: any, status: 'approved' | 'rejected') {
		sia[status] = true;
		const updateObj = {
			qualifications: [
				{
					uuid: sia.uuid,
					sia_licence_validated_by_admin: status === 'approved' ? true : false,
				},
			],
		};

		this.employeesService
			.updateWorker(updateObj, this.worker.details.userUuid)
			.then((result: any) => {
				const { sia_licences } = result.data.user_job_profiles || [];
				if (sia_licences) {
					if (sia_licences && typeof sia_licences === 'object') {
						this.worker.profile.sia_licences = Object.values(sia_licences);
					} else {
						this.worker.profile.sia_licences = sia_licences;
					}
				}
				this.markSiaColor();
				this.popNotif.createNotification('success', 'Success', 'Worker status updated.');
				sia[status] = false;
			})
			.catch((err) => {
				sia[status] = false;
				this.popNotif.createNotification('error', 'There has been a problem.', 'Worker sia not updated.');
			});
	}
	markSiaColor() {
		const siaLicences = this.worker.profile?.sia_licences || [];
		if (!siaLicences || siaLicences.length === 0) return;

		siaLicences.forEach((qualif) => {
			const expired = this.checkSiaExpired(qualif.data.expiry_date);
			const aboutToExpire = this.checkSiaExpired(qualif.data.expiry_date, true);

			qualif.expired = expired;
			qualif.aboutToExpire = aboutToExpire;

			const hasImage =
				qualif?.file_attachments?.sia_licence?.front_thumb_url &&
				qualif?.file_attachments?.sia_licence?.back_thumb_url;

			if (!hasImage) {
				this.siaLicenceColor = 'danger';
				return;
			}

			switch (qualif.validated_decision) {
				case 'approved':
					this.siaLicenceColor = qualif.aboutToExpire ? 'warning' : 'green';
					break;
				case 'rejected':
					this.siaLicenceColor = 'danger';
					break;
				default:
					this.siaLicenceColor = '';
					break;
			}
		});
	}
}
